import axios from "axios";
import { Formik } from "formik";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Banner from "../Component/Banner";

const Modal1 = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const handleItemClick2 = () => {
    // Navigate to the specified route
    navigate("/admindashboard");
  };

  const BillSchema = Yup.object().shape({
    year: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    month: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    location: Yup.string().required("Location is required"),
    building_person: Yup.string().required("Required"),
    comment: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),

    account: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    check_no: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    type: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    bokeya_paona: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    document_no: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    amount: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    date: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),

    // bill_year: ,
    // bill_month: ,
    // bill_date:,
  });

  const handleBillSubmit = (values, actions) => {
    axios
      .post("http://localhost:8000/api/nogod", {
        year: values.year,
        month: values.month,
        date: values.date,
        location: values.location,
        building_person: values.building_person,
        type: values.type,
        comment: values.comment,
        document_no: values.document_no,
        amount: values.amount,
        bokeya_paona: values.bokeya_paona,
        check_no: values.check_no,
        account: values.account,
      })

      .then(function (response) {
        if (response.status === 200) {
          alert("Submitted Successfully");
          actions.resetForm();
          handleClose();
        }

        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
        <Banner
        banner={"নগদ গ্রহন"}
        handleItemClick2={handleItemClick2}
        handleItemClick={handleShow}
        backbutton={"পিছনে ফিরে যান "}
        pressbutton={"নগদ গ্রহন"}
      />
      <Modal
        show={show}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>নগদ গ্রহন</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              year: "0",
              month: "0",
              date: "0",
              location: "",
              building_person: "",
              type: "0",
              account: "0",
              amount: "0",
              document_no: "0",
              bokeya_paona: "0",
              check_no: "0",
              comment: "",
            }}
            validationSchema={BillSchema}
            onSubmit={handleBillSubmit}
          >
            {({
              errors,
              touched,
              handleSubmit,
              handleChange,
              handleBlur,
              values,
            }) => (
              <form onSubmit={handleSubmit} style={{ margin: "20px" }}>
                <div class="row">
                  <div class="form-group col-md-3">
                    <label for="input4"> বছর</label>
                    <input
                      type=""
                      class="form-control"
                      id="input4"
                      placeholder=""
                      name="year"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.year}
                    />
                    {touched.year && errors.year && (
                      <div style={{ color: "red" }}>{errors.year}</div>
                    )}
                  </div>
                  <div class="form-group col-md-2">
                    <label for="input4"> মাস</label>
                    <select
                      id="inputState"
                      name="month"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.month}
                      class="form-control"
                    >
                      <option selected>Jan</option>
                      <option>Feb</option>
                      <option>March</option>
                      <option>April</option>
                      <option>May</option>
                      <option>Jun</option>
                      <option>Julay</option>
                      <option>Aug</option>
                      <option>Sept</option>
                      <option>Nov</option>
                      <option>Dec</option>
                    </select>
                    {touched.month && errors.month && (
                      <div style={{ color: "red" }}>{errors.month}</div>
                    )}
                  </div>
                  <div class="form-group col-md-3">
                    <label for="input4">গ্রহনের তারিখ </label>
                    <input
                      type="date"
                      class="form-control"
                      id="input4"
                      placeholder=""
                      name="date"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.date}
                    />
                    {touched.date && errors.date && (
                      <div style={{ color: "red" }}>{errors.date}</div>
                    )}
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputState">প্রজেক্ট লোকেশান</label>
                    <select
                      id="inputState"
                      name="location"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.location}
                      class="form-control"
                    >
                      <option selected>Choose...</option>
                      <option>Choose2...</option>
                    </select>
                    {touched.location && errors.location && (
                      <div style={{ color: "red" }}>{errors.location}</div>
                    )}
                  </div>
                  <div class="form-group col-md-3">
                    <label for="inputState">গ্রহনের ধরন</label>
                    <select
                      id="inputState"
                      name="type"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.type}
                      class="form-control"
                    >
                      <option selected>Bank Receipt</option>
                      <option>Cash Receipt</option>
                    </select>
                    {touched.type && errors.type && (
                      <div style={{ color: "red" }}>{errors.type}</div>
                    )}
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputState">গ্রহণ একাউন্ট</label>
                    <select
                      id="inputState"
                      name="account"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.account}
                      class="form-control"
                    >
                      <option selected>Cash in Hand</option>
                      <option>Prime Bank Banani BR.</option>
                    </select>
                    {touched.account && errors.account && (
                      <div style={{ color: "red" }}>{errors.account}</div>
                    )}
                  </div>
                  <div class="form-group col-md-3">
                    <label for="inputState">চেক নং</label>
                    <input
                      id="inputState"
                      name="check_no"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.check_no}
                      class="form-control"
                    ></input>
                    {touched.check_no && errors.check_no && (
                      <div style={{ color: "red" }}>{errors.check_no}</div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div class="form-group col-md-4">
                    <label for="inputState">বিল্ডিং বাসিন্দা</label>
                    <select
                      id="inputState"
                      name="building_person"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.building_person}
                      class="form-control"
                    >
                      <option selected>Choose...</option>
                      <option>Choose2...</option>
                    </select>
                    {touched.building_person && errors.building_person && (
                      <div style={{ color: "red" }}>
                        {errors.building_person}
                      </div>
                    )}
                  </div>
                  <div class="form-group col-md-2">
                    <label for="inputState">বকেয়া পাওনা</label>
                    <input
                      id="inputState"
                      name="bokeya_paona"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.bokeya_paona}
                      class="form-control"
                    ></input>
                    {touched.bokeya_paona && errors.bokeya_paona && (
                      <div style={{ color: "red" }}>{errors.bokeya_paona}</div>
                    )}
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputState">রেফারেন্স ডকুমেন্ট নং</label>
                    <input
                      id="inputState"
                      name="document_no"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.document_no}
                      class="form-control"
                    ></input>
                    {touched.document_no && errors.document_no && (
                      <div style={{ color: "red" }}>{errors.document_no}</div>
                    )}
                  </div>
                  <div class="form-group col-md-2">
                    <label for="inputState">বিলের পরিমান</label>
                    <input
                      id="inputState"
                      name="amount"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.amount}
                      class="form-control"
                    ></input>
                    {touched.amount && errors.amount && (
                      <div style={{ color: "red" }}>{errors.amount}</div>
                    )}
                  </div>
                  <div class="form-group col-md-6">
                    <label for="inputState">মন্তব্য</label>
                    <input
                      id="inputState"
                      name="comment"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.comment}
                      class="form-control"
                    ></input>
                    {touched.comment && errors.comment && (
                      <div style={{ color: "red" }}>{errors.comment}</div>
                    )}
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "10px 0px",
                  }}
                >
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button type="submit" variant="primary">
                    Save
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

export default Modal1;

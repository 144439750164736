import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Banner from "../Component/Banner";

const Report = () => {
  const [formData, setFormData] = useState({});
  const [showData, setShowData] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const navigate = useNavigate();


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleButtonClick = () => {
    setShowData(true);
  };
  const handleItemClick2 = () => {
    // Navigate to the specified route
    navigate("/admindashboard");
  };

  const handlePrintButtonClick = (data) => {
    console.log(data); // Print the data to the console
    // Perform any other printing logic here
    window.print()
  };

  return (
    <div style={{ backgroundColor: "#fbf9f8" }}>
      <Banner banner={"বিল প্রিন্ট"} backbutton={"পিছনে ফিরে যান "} handleItemClick2={handleItemClick2} pressbutton={''} />



      <div className="container">
        <div className="row" style={{ padding: "20px" }}>
          <div
            className="col-md-7"
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
              boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)",
            }}
          >
            <form>
              <div className="row">
                <div
                  className="form-group col-md-6"
                  style={{ padding: "20px" }}
                >
                  <label htmlFor="inputMOnth">মাস</label>
                  <select
                    type="text"
                    name="month"
                    value={formData.month || ""}
                    onChange={handleChange}
                    placeholder="month"
                    className="form-control"
                  >
                    <option>Jan</option>
                    <option>Feb</option>
                    <option>March</option>
                    <option>April</option>
                    <option>Jun</option>
                    <option>Julay</option>
                    <option>Aug</option>
                    <option>Sep</option>
                    <option>Oct</option>
                    <option>Nov</option>
                    <option>Dec</option>
                  </select>
                </div>
                <div
                  className="form-group col-md-6"
                  style={{ padding: "20px" }}
                >
                  <label htmlFor="inputMOnth">বছর</label>
                  <input
                    type="text"
                    name="year"
                    value={formData.year || ""}
                    onChange={handleChange}
                    placeholder=""
                    className="form-control"
                  />
                </div>
                <div className="form-group" style={{ padding: "20px" }}>
                  <label htmlFor="inputMOnth">বিল্ডিং বাসিন্দা</label>
                  <select
                    type="text"
                    name="date"
                    value={formData.date || ""}
                    onChange={handleChange}
                    placeholder="date"
                    className="form-control"
                  >
                    <option>1</option>
                    <option>2</option>
                  </select>
                </div>
              </div>
              <div className="" style={{ padding: "20px" }}>
                <p>রিপোর্ট ভার্সন</p> {" "}
                <input
                  type="radio"
                  id="html"
                  name="fav_language"
                  value="HTML"
                />
                  <label for="html">English</label>
                <br /> {" "}
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="CSS"
                />  <label for="css">বাংলা</label>
                <br></br>
              </div>
            </form>
            <div
              className=""
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "20px",
              }}
            >
              <Button variant="success" onClick={handleButtonClick}>
                Show Data
              </Button>
              <Button
                variant="secondary"
                onClick={() => handlePrintButtonClick(formData)}
              >
                Print Data
              </Button>
            </div>
          </div>
          {showData && (
            <div
              className="col-md-4 print-preview"
              style={{
                backgroundColor: "white",
                borderRadius: "5px",
                boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)",
                margin: "0px 10px",
              }}
            >
              <form>
                <div className="row">
                  <div
                    className="form-group col-md-6"
                    style={{ padding: "20px" }}
                  >
                    <label htmlFor="inputMOnth">বছর</label>
                    <input
                      type="text"
                      name="year"
                      value={formData.year || ""}
                      onChange={handleChange}
                      placeholder=""
                      className="form-control"
                    />
                  </div>
                  <div
                    className="form-group col-md-6"
                    style={{ padding: "20px" }}
                  >
                    <label htmlFor="inputMOnth">মাস</label>
                    <input
                      type="text"
                      name="year"
                      value={formData.month || ""}
                      onChange={handleChange}
                      placeholder=""
                      className="form-control"
                    />
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Report;

import React from "react";
import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";
import {
  faAddressBook,
  faCog,
  faCreditCard,
  faHome,
  faMoneyBill,
  faPrint,
  faStickyNote,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Management = () => {
  return (
    <>
      <div style={{ margin: "20px" }} className="">
        <Card>
          <Card.Body
            style={{
              boxShadow: "0 0 6px #a7a7a7",
              backgroundColor: "#514c47",
            }}
          >
            <Nav className="me-auto">
              <Nav.Link style={{ color: "white" }} href="/">
                <h3 style={{fontSize:"18px"}}>ফ্যাসিলিটিজ ম্যানেজমেন্ট</h3>
              </Nav.Link>
            </Nav>
          </Card.Body>
        </Card>
      </div>
      <div className="row">
        <div className="col-md-4">
          
          <Card style={{ margin: "20px" }} >
            <Card.Header>অ্যাসোসিয়েশন কমিটি</Card.Header>
            <div className="row">
              <div className="col-md-6">
              <a
            href=""
            style={{
              textDecoration: "none",
              fontSize: "18px",
              fontWeight: "bold",
              color: "black",
            }}
          >
            <Card className="box" style={{ margin: "20px" }}>
             
              <Card.Header
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "15px",
                  backgroundColor: "#fff",
                }}
                as="h5"
              >
                {" "}
                <Card.Title
                  style={{
                    fontSize: "17px",
                    fontWeight: "600",
                    color: "#587b86",
                  }}
                >
                  ম্যানেজমেন্ট কমিটি
                </Card.Title>
                <FontAwesomeIcon
                  style={{
                    backgroundColor: "#587b86",
                    padding: "6px",
                    borderRadius: " 6px",
                    color: "white",
                  }}
                  icon={faAddressBook}
                  fixedWidth
                />
              </Card.Header>

              <Card.Body>
                <h5>ম্যানেজমেন্ট কমিটি তৈরি করুন</h5>
              </Card.Body>
            </Card>
          </a>
              </div>
              <div className="col-md-6">
              <a
            href=""
            style={{
              textDecoration: "none",
              fontSize: "18px",
              fontWeight: "bold",
              color: "black",
            }}
          >
            <Card className="box" style={{ margin: "20px" }}>
             
              <Card.Header
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "15px",
                  backgroundColor: "#fff",
                }}
                as="h5"
              >
                {" "}
                <Card.Title
                  style={{
                    fontSize: "17px",
                    fontWeight: "600",
                    color: "#587b86",
                  }}
                >
                  মিটিং মিনিটস
                </Card.Title>
                <FontAwesomeIcon
                  style={{
                    backgroundColor: "#587b86",
                    padding: "6px",
                    borderRadius: " 6px",
                    color: "white",
                  }}
                  icon={faAddressBook}
                  fixedWidth
                />
              </Card.Header>

              <Card.Body>
                <h5>মিটিং মিনিটস আপলোড করুন</h5>
              </Card.Body>
            </Card>
          </a>
              </div>
            </div>
          
          </Card>
        </div>

        <div className="col-md-4">
          <Card style={{ margin: "20px" }} >
            <Card.Header>বাসিন্দাদের অ্যাকাউন্ট তৈরি</Card.Header>
          <a
            href=""
            style={{
              textDecoration: "none",
              fontSize: "18px",
              fontWeight: "bold",
              color: "black",
            }}
          >
            <Card className="box" style={{ margin: "20px" }}>
             
              <Card.Header
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "15px",
                  backgroundColor: "#fff",
                }}
                as="h5"
              >
                {" "}
                <Card.Title
                  style={{
                    fontSize: "17px",
                    fontWeight: "600",
                    color: "#587b86",
                  }}
                >
                 অ্যাকাউন্ট তৈরি
                </Card.Title>
                <FontAwesomeIcon
                  style={{
                    backgroundColor: "#587b86",
                    padding: "6px",
                    borderRadius: " 6px",
                    color: "white",
                  }}
                  icon={faAddressBook}
                  fixedWidth
                />
              </Card.Header>

              <Card.Body>
                <h5>বিল্ডিং বাসিন্দাদের জন্য অ্যাকাউন্ট তৈরি করুন</h5>
              </Card.Body>
            </Card>
          </a>
          </Card>
        </div>
        <div className="col-md-4">
          <Card style={{ margin: "20px" }} >
            <Card.Header>মেইনটেনেন্স শিডিউল</Card.Header>
          <a
            href=""
            style={{
              textDecoration: "none",
              fontSize: "18px",
              fontWeight: "bold",
              color: "black",
            }}
          >
            <Card className="box" style={{ margin: "20px" }}>
             
              <Card.Header
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "15px",
                  backgroundColor: "#fff",
                }}
                as="h5"
              >
                {" "}
                <Card.Title
                  style={{
                    fontSize: "17px",
                    fontWeight: "600",
                    color: "#587b86",
                  }}
                >
                 মেইন্টেনেন্স ক্যালেন্ডার 
                </Card.Title>
                <FontAwesomeIcon
                  style={{
                    backgroundColor: "#587b86",
                    padding: "6px",
                    borderRadius: " 6px",
                    color: "white",
                  }}
                  icon={faAddressBook}
                  fixedWidth
                />
              </Card.Header>

              <Card.Body>
                <h5>মেইনটেনেন্স এর শিডিউল তৈরি করুন</h5>
              </Card.Body>
            </Card>
          </a>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Management;

import React from 'react'
import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";
import HomeNavBar from '../Component/HomeNavBar';
import HomeCard from "../Component/HomeCard"
import { faAddressBook, faCog, faCreditCard, faHome, faMoneyBill, faPrint, faStickyNote } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HomeCard2 from '../Component/HomeCard2';




const accountInformation=[{
  icon: faStickyNote,
    title: "ইউটিলিটি সহ মাসিক বিল",
    text: "ভাড়া, পানির বিল, বিদ্যুৎ বিল সহ অন্যান্য বিল তৈরি",
    value: "2",
},
{
  icon: faStickyNote,
    title: "ইউটিলিটি সহ মাসিক বিল",
    text: "ভাড়া, পানির বিল, বিদ্যুৎ বিল সহ অন্যান্য বিল তৈরি",
    value: "2",
},
{
  icon: faStickyNote,
    title: "ইউটিলিটি সহ মাসিক বিল",
    text: "ভাড়া, পানির বিল, বিদ্যুৎ বিল সহ অন্যান্য বিল তৈরি",
    value: "2",
}
,
{
  icon: faStickyNote,
    title: "ইউটিলিটি সহ মাসিক বিল",
    text: "ভাড়া, পানির বিল, বিদ্যুৎ বিল সহ অন্যান্য বিল তৈরি",
    value: "2",
}]


const lastaccount=[{
  icon: faStickyNote,
    title: "ইউটিলিটি সহ মাসিক বিল",
    text: "ভাড়া, পানির বিল, বিদ্যুৎ বিল সহ অন্যান্য বিল তৈরি",
    value: "2",
}

]

const Account = () => {
  return (
    <>
    <div style={{ margin: "20px" }}>
    <Card>
    <Card.Body style={{ boxShadow: "0 0 6px #a7a7a7",backgroundColor:"aliceblue", }}>
      <Nav className="me-auto" style={{display:"block"}}>
        <h5>অ্যাকাউন্টিং</h5>
        <Nav.Link style={{ color: "black" }} href="/">
        বিল্ডিং বাসিন্দাদের থেকে টাকা গ্রহন সহ বিভিন্ন ব্যয়ের বিল তৈরি। ৩০ টির ও অধিক ব্যয়ের হেড (এক্সপেন্স হেড) তৈরি করে দেয়া আছে, এরপর ও যদি আরও ব্যয়ের হেড (এক্সপেন্স হেড) তৈরি করতে হয় তাহলে <br />
        <a href="" style={{textDecoration:"none",fontSize:"18px",fontWeight:"bold"}}>জিএল হেড তৈরি করে নিতে পারবেন।</a> 
        </Nav.Link>
        <Nav.Link style={{ color: "black" }} href="dashboard">
          
        </Nav.Link>
        <Nav.Link style={{ color: "black" }} href="services">
          
        </Nav.Link>
        <Nav.Link style={{ color: "black" }} href="report">
          
        </Nav.Link>
      </Nav>
    </Card.Body>
  </Card>
  </div>
  
  <div className="" style={{ margin: "20px" }}>
    <div className="row">
      <div className="col-md-7">
        <Card>
          <a href="" style={{textDecoration:"none",fontSize:"18px",fontWeight:"bold",color:"black"}}>
          <Card.Header>
          অ্যাকাউন্টিং
          </Card.Header>
          </a>
          <Card.Body>
            <div className="row">
              <div className="col-md-12">
                <HomeCard HomecardInformation={accountInformation} />
              </div>

              
            </div>
          </Card.Body>
        </Card>
        </div>
        <div className="col-md-3">
          <a href="" style={{textDecoration:"none",fontSize:"18px",fontWeight:"bold",color:"black"}}>
        <Card className="box" >
                <Card.Header
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "15px",
                    backgroundColor: "#fff",
                  }}
                  as="h5"
                >
                  {" "}
                  <Card.Title style={{ fontSize: "17px", fontWeight: "600",color:'#587b86' }}>
                  রিপোর্ট
                  </Card.Title>
                  <FontAwesomeIcon
                    style={{
                      backgroundColor: "#587b86",
                      padding: "6px",
                      borderRadius: " 6px",
                      color: "white",
                    }}
                    
                    fixedWidth
                  />
                </Card.Header>

                <Card.Body>
                  
                </Card.Body>
              </Card>
              </a>
              </div>
              <div className="col-md-2">

                <a href="" style={{textDecoration:"none",fontSize:"18px",fontWeight:"bold",color:"black"
                }}>
              <Card className="box" >
                <Card.Header
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "15px",
                    backgroundColor: "#fff",
                  }}
                  as="h5"
                >
                  {" "}
                  <Card.Title style={{ fontSize: "17px", fontWeight: "600",color:'#587b86' }}>
                  অ্যাকাউন্টস হেড
                  </Card.Title>
                  <FontAwesomeIcon
                    style={{
                      backgroundColor: "#587b86",
                      padding: "6px",
                      borderRadius: " 6px",
                      color: "white",
                    }}
                    icon={faAddressBook}
                    fixedWidth
                  />
                </Card.Header>

                <Card.Body>
                  <p style={{ fontSize: "14px" }}>জিএল</p>
                  <h5>অ্যাকাউন্টস হেড তৈরি</h5>
                </Card.Body>
              </Card>
              </a>
              </div>
        </div>
        
      </div>
  
  </>


  )
}

export default Account
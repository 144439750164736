import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./Component/Layout/Header";
import LocationForm from "./Component/LocationForm";
import Account from "./Page/Account";
import Admindashboard from "./Page/AdminDashbord";
import Billing from "./Page/Billing";
import BuildingLocation from "./Page/BuildingLocation";
import BuyBill from "./Page/BuyBill";
import Dashboard from "./Page/Dashboard";
import FlatInformation from "./Page/FlatInformation";
import Home from "./Page/Home";
import Login from "./Page/Login";
import Management from "./Page/Management";
import MonthBill from "./Page/MonthBill";
import MonthBillForm from "./Page/MonthBillForm";
import Report from "./Page/Report";
import RegistrationPage from "./Page/ResgistrationPage";
import Resived from "./Page/Resived";
import Services from "./Page/Services";
import Singin from "./Page/Singin";



function App({ access_token }) {
  console.log("access_token", access_token);

  let routes = (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/singin" element={<Singin />} />
      <Route path="/registation" element={<RegistrationPage />} />
      <Route path="/login" element={<Login />} />
    </Routes>
  );




  if (access_token && access_token.length) {
    routes = (
      <>
      <Header />
      <Routes>
        <Route path="/admindashboard" element={<Admindashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/services" element={<Services />} />
        <Route path="/report" element={<Report />} />
        <Route path="/billing" element={<Billing />} />
        <Route path="/account" element={<Account />} />
        <Route path="/management" element={<Management />} />
        <Route path="/monthbill" element={<MonthBill />} />
        <Route path="/monthbillform" element={<MonthBillForm />} />
        <Route path="/resived" element={<Resived />} />
        <Route path="/buybill" element={<BuyBill />} />
        <Route path="/location" element={<BuildingLocation access_token={access_token} />} />
        <Route path="/locationform" element={<LocationForm />} />
        <Route path="/flatInformation" element={<FlatInformation />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
      </>
    );
   
  }

  return (
    <>
      {routes}
    </>
  );
}

export default App;

import React from 'react'
import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";
import { useNavigate } from 'react-router-dom';


const HomeNavBar = () => {

  const navigate = useNavigate();

  const handleClick = () => {
    // Navigate to the specified route
    navigate('/admindashboard');
  };
  const handleClick2 = () => {
    // Navigate to the specified route
    navigate('/dashboard');
  };const handleClick3 = () => {
    // Navigate to the specified route
    navigate('/services');
  };const handleClick4 = () => {
    // Navigate to the specified route
    navigate('/services');
  };
  return (
    <div><div style={{ margin: "20px" }} className="">
    <Card>
      <Card.Body style={{ boxShadow: "0 0 6px #a7a7a7" }}>
        <Nav className="me-auto">
          <Nav.Link onClick={handleClick} style={{ color: "black" }}>
            কুইক স্টার্ট
          </Nav.Link>
          <Nav.Link style={{ color: "black" }} onClick={handleClick2}>
            ড্যাশবোর্ড
          </Nav.Link>
          <Nav.Link style={{ color: "black" }} onClick={handleClick3}>
            অ্যাড অন সার্ভিস
          </Nav.Link>
          <Nav.Link style={{ color: "black" }} onClick={handleClick4}>
            অন্যান্য রিপোর্ট
          </Nav.Link>
        </Nav>
      </Card.Body>
    </Card>
  </div></div>
  )
}

export default HomeNavBar
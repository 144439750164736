import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Banner from "../Component/Banner";

const MonthBill = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetch("https://api.shopnobash.com/api/utilities")
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.log(error));
  };

  const navigate = useNavigate();

  const handleItemClick = () => {
    // Navigate to the specified route
    navigate("/monthbillform");
  };
  const handleItemClick2 = () => {
    // Navigate to the specified route
    navigate("/admindashboard");
  };

  return (
    <div>
      <Banner
        banner={"বিল্ডিং বাসিন্দাদের মাসিক বিল"}
        handleItemClick={handleItemClick}
        handleItemClick2={handleItemClick2}
        backbutton={"পিছনে ফিরে যান "}
        pressbutton="বিল তৈরি"
      />
      <div className="" style={{ margin: "10px" }}>
        <Card>
          <div
            className=""
            style={{ padding: "10px", backgroundColor: "#f5f4f2" }}
          >
            <form class="example" action="" style={{ margin: "auto" }}>
              <button
                type="submit"
                style={{
                  backgroundColor: "#6fb576",
                  height: "38px",
                  width: "50px",
                  border: "none",
                  borderRadius: "7px",
                  marginRight: "5px",
                }}
              >
                <i class="fa fa-search"></i>
              </button>
              <input
                style={{
                  height: "38px",
                  width: "250px",
                  border: "1px solid black",
                  borderRadius: "7px",
                  marginRight: "5px",
                }}
                type="text"
                placeholder="Search.."
                name="search"
              />
              <button
                type="submit"
                style={{
                  backgroundColor: "#6fb576",
                  height: "38px",
                  width: "50px",
                  border: "none",
                  borderRadius: "7px",
                  marginRight: "5px",
                }}
              >
                Go
              </button>
              <button
                type="submit"
                style={{
                  backgroundColor: "#6fb576",
                  height: "38px",
                  width: "90px",
                  border: "none",
                  borderRadius: "7px",
                  marginRight: "5px",
                }}
              >
                Action
              </button>
            </form>
          </div>
        </Card>
        <table
          id="dtBasicExample"
          class="table table-striped table-bordered table-sm"
          cellspacing="0"
          width="100%"
        >
          <thead>
            <tr>
              <th class="th-sm">Document No</th>
              <th class="th-sm">Occupant</th>
              <th class="th-sm">Bill Date</th>
              <th class="th-sm">Bill Month</th>
              <th class="th-sm">Bill Year</th>
              <th class="th-sm">Total Bill</th>
              <th class="th-sm">Bill Status</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.location}</td>
                <td>{item.bill_date}</td>
                <td>{item.bill_month}</td>
                <td>{item.bill_year}</td>
                <td>{item.motbill}</td>
                <td>{item.bill_month}</td>
              </tr>
            ))}
          </tbody>
          {/* <tfoot>
    <tr>
      <th>Name
      </th>
      <th>Position
      </th>
      <th>Office
      </th>
      <th>Age
      </th>
      <th>Start date
      </th>
      <th>Salary
      </th>
    </tr>
  </tfoot> */}
        </table>
      </div>
    </div>
  );
};

export default MonthBill;

import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import "./index.css";

const HomeCard = ({ HomecardInformation }) => {
  return (
    <div>
      
        {HomecardInformation.map(({ icon, text, title, value }) => (
          
            <a href="" className="underline">
              <Card className="box" style={{ marginTop: "20px" }}>
                <Card.Header
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "15px",
                    backgroundColor: "#fff",
                  }}
                  as="h5"
                >
                  {" "}
                  <Card.Title style={{ fontSize: "17px", fontWeight: "600",color:'#587b86' }}>
                    {title}
                  </Card.Title>
                  <FontAwesomeIcon
                    style={{
                      backgroundColor: "#587b86",
                      padding: "6px",
                      borderRadius: " 6px",
                      color: "white",
                    }}
                    icon={icon}
                    fixedWidth
                  />
                </Card.Header>

                <Card.Body>
                  <p style={{ fontSize: "14px" }}>{text}</p>
                  <h5>{value}</h5>
                </Card.Body>
              </Card>
            </a>
          
        ))}
    
    </div>
  );
};

export default HomeCard;

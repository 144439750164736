import React from "react";
import logo from "../../assets/db2.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';



const Header = () => {
  const navigate = useNavigate();
 

  const handleClick = () => {
    // Navigate to the specified route
    navigate('/admindashboard');
  };
  const handleClick2 = () => {
    // Navigate to the specified route
    navigate('/billing');
  };const handleClick3 = () => {
    // Navigate to the specified route
    navigate('/account');
  };const handleClick4 = () => {
    // Navigate to the specified route
    navigate('/management');
  };
  const logout = () => {
    // Remove the token from local storage
    localStorage.removeItem('access_token');
    // Perform any other logout-related tasks (e.g., redirecting to the login page)
    // ...
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };


  return (
    <div>
      <div style={{ margin: "20px" }} className="">
      <div className="logout">
          <img onClick={handleClick} style={{ height: "50px" }} src={logo} alt="" />
          
          <FaSignOutAlt style={{cursor:"pointer"}} onClick={handleLogout} />
          </div>
      </div>

      <Navbar bg="dark" variant="dark"style={{padding:"20px"}}>
        <Container>
          <Nav
            className="me-auto"
            style={{ width: "100%", justifyContent: "space-between", }}
          >
            <Nav.Link onClick={handleClick}><p style={{fontSize:"18px"}}>হোম</p></Nav.Link>
            <Nav.Link onClick={handleClick2}> <h4 style={{fontSize:"18px"}}>বিলিং</h4></Nav.Link>
            <Nav.Link onClick={handleClick3}> <h4 style={{fontSize:"18px"}}>অ্যাকাউন্টিং</h4></Nav.Link>
            <Nav.Link onClick={handleClick4}> <h4 style={{fontSize:"18px"}}> ফ্যাসিলিটিজ ম্যানেজমেন্ট  </h4></Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      
    </div>
  );
};

export default Header;

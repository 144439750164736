import axios from "axios";
import { Formik } from "formik";
import React from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Banner from "../Component/Banner";

const MonthBillForm = () => {
  const navigate = useNavigate();

  const BillSchema = Yup.object().shape({
    bill_year: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    bill_month: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    location: Yup.string().required("Location is required"),
    building_person: Yup.string()
      .required("Required"),
    gash_bill: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),

    vara: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    servics_charge: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    parking_charge: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    pani_bill: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    security_bill: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    others_bill: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    eleticity_bill: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    average_eleticity_bill: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    bokaya_bill: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    present_bill: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    bill_rivate: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    motbill: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),

    bill_date: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),

    // bill_year: ,
    // bill_month: ,
    // bill_date:,
  });


  

  const handleBillSubmit = (values, actions) => {
    axios
      .post("https://api.shopnobash.com/api/utilities", {
        bill_year: values.bill_year,
        bill_month: values.bill_month,
        bill_date: values.bill_date,
        location: values.location,
        building_person: values.building_person,
        vara: values.vara,
        servics_charge: values.servics_charge,
        parking_charge: values.parking_charge,
        gash_bill: values.gash_bill,
        pani_bill: values.pani_bill,
        security_bill: values.security_bill,
        others_bill: values.others_bill,
        eleticity_bill: values.eleticity_bill,
        average_eleticity_bill: values.average_eleticity_bill,
        bokaya_bill: values.bokaya_bill,
        present_bill: values.present_bill,
        bill_rivate: values.bill_rivate,
        motbill: values.motbill,
      })
      .then(function (response) {
        if (response.status===200) {
          alert("Submitted Successfully");
          actions.resetForm();
          navigate("/monthbill");
        }
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  
  const handleItemClick2 = () => {
    // Navigate to the specified route
    navigate("/monthbill");
  };
  const handleItemClick = () => {
    // Navigate to the specified route
    navigate("/admindashboard");
  };
  

  return (
    <div>
      <Banner banner={"মাসিক ইউটিলিটি বিল"} pressbutton={''} handleItemClick={handleItemClick}  handleItemClick2={handleItemClick2}backbutton={"পিছনে ফিরে যান "}  />

      <Formik
        initialValues={{
          bill_year: "",
          bill_month: "",
          bill_date: "",
          location: "",
          building_person: "",
          vara: "0",
          servics_charge: "0",
          parking_charge: "0",
          gash_bill: "0",
          pani_bill: "0",
          security_bill: "0",
          others_bill: "0",
          eleticity_bill: "0",
          average_eleticity_bill: "0",
          bokaya_bill: "0",
          present_bill: "0",
          bill_rivate: "0",
          motbill: "0",
        }}



        
        validationSchema={BillSchema}

        
        onSubmit={handleBillSubmit}
      >
        {({
          errors,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
          values,
        }) => (
          <form onSubmit={handleSubmit} style={{ margin: "20px" }}>
            <div class="row">
              <div class="form-group col-md-2">
                <label for="input4">বিলিং বছর</label>
                <input
                  type=""
                  class="form-control"
                  id="input4"
                  placeholder=""
                  name="bill_year"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.bill_year}
                />
                {touched.bill_year && errors.bill_year && (
                  <div style={{ color: "red" }}>{errors.bill_year}</div>
                )}
              </div>
              <div class="form-group col-md-2">
                <label for="input4">বিলিং মাস</label>
                <select
                  id="inputState"
                  name="bill_month"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.bill_month}
                  class="form-control"
                >
                  <option selected>Jan</option>
                  <option>Feb</option>
                  <option>March</option>
                  <option>April</option>
                  <option>May</option>
                  <option>Jun</option>
                  <option>Julay</option>
                  <option>Aug</option>
                  <option>Sept</option>
                  <option>Nov</option>
                  <option>Dec</option>
                </select>
                {touched.bill_month && errors.bill_month && (
                  <div style={{ color: "red" }}>{errors.bill_month}</div>
                )}
              </div>
              <div class="form-group col-md-2">
                <label for="input4">বিলিং তারিখ</label>
                <input
                  type="date"
                  class="form-control"
                  id="input4"
                  placeholder=""
                  name="bill_date"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.bill_date}
                />
                {touched.bill_date && errors.bill_date && (
                  <div style={{ color: "red" }}>{errors.bill_date}</div>
                )}
              </div>
              <div class="form-group col-md-2">
                <label for="input4">প্রজেক্ট লোকেশান</label>
                <input
                  type=""
                  class="form-control"
                  id="input4"
                  placeholder=""
                  name="location"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.location}
                />
                {touched.location && errors.location && (
                  <div style={{ color: "red" }}>{errors.location}</div>
                )}
              </div>
              <div class="form-group col-md-4">
                <label for="inputState">বিল্ডিং এর বাসিন্দা</label>
                <select
                  id="inputState"
                  name="building_person"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.building_person}
                  class="form-control"
                >
                  <option selected>Choose...</option>
                  <option>Choose2...</option>
                </select>
                {touched.building_person && errors.building_person && (
                  <div style={{ color: "red" }}>{errors.building_person}</div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <Card
                  className=""
                  style={{
                    marginTop: "25px",
                    height: "180px",
                    padding: "15px",
                  }}
                >
                  <Card.Header>আয়ের খাত</Card.Header>
                  <div className="row">
                    <div
                      class="form-group col-md-4"
                      style={{ marginTop: "20px" }}
                    >
                      <label for="input4">ভাড়া</label>
                      <input
                        type="value"
                        class="form-control"
                        id="input4"
                        placeholder=""
                        name="vara"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.vara}
                      />
                      {touched.vara && errors.vara && (
                        <div style={{ color: "red" }}>{errors.vara}</div>
                      )}
                    </div>
                    <div
                      class="form-group col-md-4"
                      style={{ marginTop: "20px" }}
                    >
                      <label for="input4">সার্ভিস চার্জ</label>
                      <input
                        type=""
                        class="form-control"
                        id="input4"
                        placeholder=""
                        name="servics_charge"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.servics_charge}
                      />
                      {touched.servics_charge && errors.servics_charge && (
                        <div style={{ color: "red" }}>
                          {errors.servics_charge}
                        </div>
                      )}
                    </div>
                    <div
                      class="form-group col-md-4"
                      style={{ marginTop: "20px" }}
                    >
                      <label for="input4">পার্কিং চার্জ</label>
                      <input
                        type=""
                        class="form-control"
                        id="input4"
                        placeholder=""
                        name="parking_charge"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.parking_charge}
                      />
                      {touched.parking_charge && errors.parking_charge && (
                        <div style={{ color: "red" }}>
                          {errors.parking_charge}
                        </div>
                      )}
                    </div>
                  </div>
                </Card>
              </div>
              <div className="form-group col-md-6">
                <Card
                  className=""
                  style={{
                    marginTop: "25px",
                    height: "180px",
                    padding: "15px",
                  }}
                >
                  <Card.Header>ইউলিটিজ বিল</Card.Header>
                  <div className="row">
                    <div
                      class="form-group col-md-3"
                      style={{ marginTop: "20px" }}
                    >
                      <label for="input4">গ্যাস বিল</label>
                      <input
                        type=""
                        class="form-control"
                        id="input4"
                        placeholder=""
                        name="gash_bill"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.gash_bill}
                      />
                      {touched.gash_bill && errors.gash_bill && (
                        <div style={{ color: "red" }}>{errors.gash_bill}</div>
                      )}
                    </div>
                    <div
                      class="form-group col-md-3"
                      style={{ marginTop: "20px" }}
                    >
                      <label for="input4">পানি বিল</label>
                      <input
                        type=""
                        class="form-control"
                        id="input4"
                        placeholder=""
                        name="pani_bill"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.pani_bill}
                      />
                      {touched.pani_bill && errors.pani_bill && (
                        <div style={{ color: "red" }}>{errors.pani_bill}</div>
                      )}
                    </div>
                    <div
                      class="form-group col-md-3"
                      style={{ marginTop: "20px" }}
                    >
                      <label for="input4">সিকিউরিটি বিল</label>
                      <input
                        type=""
                        class="form-control"
                        id="input4"
                        placeholder=""
                        name="security_bill"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.security_bill}
                      />
                      {touched.security_bill && errors.security_bill && (
                        <div style={{ color: "red" }}>
                          {errors.security_bill}
                        </div>
                      )}
                    </div>
                    <div
                      class="form-group col-md-3"
                      style={{ marginTop: "20px" }}
                    >
                      <label for="input4">অন্যান্য বিল</label>
                      <input
                        type=""
                        class="form-control"
                        id="input4"
                        placeholder=""
                        name="others_bill"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.others_bill}
                      />
                      {touched.others_bill && errors.others_bill && (
                        <div style={{ color: "red" }}>{errors.others_bill}</div>
                      )}
                    </div>
                  </div>
                </Card>
              </div>
            </div>

            <div className="row">
              <div className="form-group ">
                <Card
                  className=""
                  style={{
                    marginTop: "25px",
                    height: "180px",
                    padding: "15px",
                  }}
                >
                  <Card.Header>বৈদ্যুতিক বিল</Card.Header>
                  <div className="row">
                    <div
                      class="form-group col-md-6"
                      style={{ marginTop: "20px" }}
                    >
                      <label for="input4">বর্তমান বিদ্যুৎ বিল</label>
                      <input
                        type=""
                        class="form-control"
                        id="input4"
                        placeholder=""
                        name="eleticity_bill"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.eleticity_bill}
                      />
                      {touched.eleticity_bill && errors.eleticity_bill && (
                        <div style={{ color: "red" }}>
                          {errors.eleticity_bill}
                        </div>
                      )}
                    </div>

                    <div
                      class="form-group col-md-6"
                      style={{ marginTop: "20px" }}
                    >
                      <label for="input4">সর্বমোট বিদ্যুৎ বিল</label>
                      <input
                        type=""
                        class="form-control"
                        id="input4"
                        placeholder=""
                        name="average_eleticity_bill"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.average_eleticity_bill}
                      />
                      {touched.average_eleticity_bill &&
                        errors.average_eleticity_bill && (
                          <div style={{ color: "red" }}>
                            {errors.average_eleticity_bill}
                          </div>
                        )}
                    </div>
                  </div>
                </Card>
              </div>
            </div>

            <div className="row">
              <div className="form-group ">
                <Card
                  className=""
                  style={{
                    marginTop: "25px",
                    height: "180px",
                    padding: "15px",
                  }}
                >
                  <Card.Header>সর্বমোট বিল</Card.Header>
                  <div className="row">
                    <div
                      class="form-group col-md-3"
                      style={{ marginTop: "20px" }}
                    >
                      <label for="input4">পূর্বের বকেয়া বিল</label>
                      <input
                        type=""
                        class="form-control"
                        id="input4"
                        placeholder=""
                        name="bokaya_bill"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.bokaya_bill}
                      />
                      {touched.bokaya_bill && errors.bokaya_bill && (
                        <div style={{ color: "red" }}>{errors.bokaya_bill}</div>
                      )}
                    </div>

                    <div
                      class="form-group col-md-3"
                      style={{ marginTop: "20px" }}
                    >
                      <label for="input4">বর্তমান বিল</label>
                      <input
                        type=""
                        class="form-control"
                        id="input4"
                        placeholder=""
                        name="present_bill"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.present_bill}
                      />
                      {touched.present_bill && errors.present_bill && (
                        <div style={{ color: "red" }}>
                          {errors.present_bill}
                        </div>
                      )}
                    </div>
                    <div
                      class="form-group col-md-3"
                      style={{ marginTop: "20px" }}
                    >
                      <label for="input4">বিল রিবেট (মউকুফ) টাকায়</label>
                      <input
                        type=""
                        class="form-control"
                        id="input4"
                        placeholder=""
                        name="bill_rivate"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.bill_rivate}
                      />
                      {touched.bill_rivate && errors.bill_rivate && (
                        <div style={{ color: "red" }}>{errors.bill_rivate}</div>
                      )}
                    </div>
                    <div
                      class="form-group col-md-3"
                      style={{ marginTop: "20px" }}
                    >
                      <label for="input4">সর্বমোট বিল</label>
                      <input
                        type=""
                        class="form-control"
                        id="input4"
                        placeholder=""
                        name="motbill"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.motbill}
                      />
                      {touched.motbill && errors.motbill && (
                        <div style={{ color: "red" }}>{errors.motbill}</div>
                      )}
                    </div>
                  </div>
                </Card>
              </div>
            </div>

            <div
              className=""
              style={{ display: "flex", justifyContent: "end" }}
            >
              <button
                style={{ margin: "10px" }}
                type="submit"
                class="btn btn-primary"
              >
                Save
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default MonthBillForm;

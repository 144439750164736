import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import logo from "../assets/db2.png";
import SinginCard from "../Component/SinginCard";
import Footer from "../Component/Footer";
import { useNavigate } from 'react-router-dom';
import HomeNavBar from "../Component/HomeNavBar";
import HomeHaderNav from "../Component/HomeHaderNav";



const pakageInformation=[{
  feature:"Free",
  amount:"0 টাকা",
  statuss:"Always Free!",
  list:['বিলিং','অ্যাকাউন্টিং',"বিল প্রিন্ট"," ইমেইল সাপোর্ট"],
  Path:"/registation"
},
{
  feature:"Standard",
  amount:"300 টাকা",
  statuss:"Based on 10 units",
  list:['SMS নোটিফিকেশন','Email নোটিফিকেশন','মানি রিসিপ্ট','বাসিন্দাদের বকেয়া রিপোর্ট','ক্যাশ ইন - আউট',],
  Path:"/registation"
},
{
  feature:"Advanced ",
  amount:"500 টাকা",
  statuss:"Based on 10 units",
  list:['কাস্টমাইজ SMS নোটিফিকেশন','বাসিন্দাদের লেজার রিপোর্ট' ,'মাসিক আয়ের রিপোর্ট','মাসিক ব্যয়ের রিপোর্ট','ব্যালেন্স শিট','হোয়াটসঅ্যাপ সাপোর্ট (24/7)'],
  Path:"/registation"
}]

const Singin = () => {

  
  const navigate = useNavigate();

  const handleClick = (Path) => {
    navigate(Path);
  };


  const [isOn, setIsOn] = useState(false);

  const handleToggle = () => {
    setIsOn(!isOn);
  };

  const [inputValue, setInputValue] = useState("");

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Input value:", inputValue);
    setInputValue("");
  };

  return (
    <div>
      <HomeHaderNav/>
      <h1 style={{ textAlign: "center" }}>প্রাইস প্যাকেজ</h1>
      <div className="">
        <h4>
          <ul style={{ display: "flex", justifyContent: "center" }}>
            <li style={{ margin: "0px 20px",color:"#356cb0" }}>কোন সেটআপ চার্জ নেই</li>
            <li style={{ margin: "0px 20px",color:"#356cb0" }}>সাশ্রয়ী মূল্য</li>
            <li style={{ margin: "0px 20px",color:"#356cb0" }}>24/7 সাপোর্ট</li>
          </ul>
        </h4>
      </div>
      <div className="inputField">
        <h4>আপনি কতগুলো ইউনিট ম্যানেজ করেন?</h4>

        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={inputValue}
            onChange={handleChange}
            placeholder="Enter your Unit"
            className="input-field"
          />
          {/* <button type="submit">Submit</button> */}
        </form>
      </div>

      <div className="monthlyPlan">
        <h4>মাসিক প্ল্যান</h4>
        <label className="toggle-switch">
          <input type="checkbox" checked={isOn} onChange={handleToggle} />
          <span className="slider"></span>
        </label>
      </div>
      <p style={{ textAlign: "center" }}>বাৎসরিক সাবস্ক্রিপশনে ২০% সাশ্রয়!</p>
<SinginCard pakageInformation={pakageInformation} handleClick={handleClick}/>
      <Footer/>
    </div>
  );
};

export default Singin;

import { faAddressBook, faCog, faCreditCard, faHome, faMoneyBill, faPrint, faStickyNote } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React,{useState,useEffect} from "react";
import Card from "react-bootstrap/Card";
import HomeCard from "../Component/HomeCard";
import HomeCard2 from "../Component/HomeCard2";
import HomeNavBar from "../Component/HomeNavBar";
import { useNavigate } from 'react-router-dom';






const HomecardInformation = [
  {
    icon: faStickyNote,
    title: "ইউটিলিটি সহ মাসিক বিল",
    text: "ভাড়া, পানির বিল, বিদ্যুৎ বিল সহ অন্যান্য বিল তৈরি",
    value: "2",
    path:'/monthbill'
  },
  {
    icon: faMoneyBill,
    title: "নগদ গ্রহন",
    text: "রিসিভ ভাউচার",
    value: "0",
    path: "/resived"
  },
  {
    icon: faPrint,
    title: "রিপোর্ট",
    text: "বিল প্রিন্ট",
    value: "0",
    path: "/report"
  },
  {
    icon: faCreditCard,
    title: "ব্যয়ের বিল ",
    text: "বেতন,বোনাস, বিল সহ যাবতীয় ব্যয়ের বিল তৈরি",
    value: "1",
    path: "/buybill"
  },
  {
    icon: faCreditCard,
    title: "নগদ প্রদান",
    text: "পেমেন্ট ভাউচার",
    value: "0",
    id:5,
  },
];

const HomecardInformation2 = [
  {
    icon: faHome,
    title: "ফ্ল্যাট",
    value: "4",
    path:'/flatInformation'

  },
  {
    icon: faAddressBook,
    title: "বাসিন্দা",
    value: "2",
  },
  {
    icon: faAddressBook,
    title: "ভেন্ডর",
    value: "0",
  },
  {
    icon: faAddressBook,
    title: "মিটার",
    value: "2",
  },
  {
    icon: faAddressBook,
    title: "লোকেশন",
    value: "0",
    path:'/location'
  },
  {
    icon: faAddressBook,
    title: "প্রতিষ্ঠান",
    value: "0",
  },
];

const Admindashboard = () => {
  const navigate = useNavigate();


  const handleItemClick = (path) => {
    // Navigate to the specified route
    navigate(path);
  };


  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetch("http://127.0.0.1:8000/api/location")
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.log(error));
  };
  

  return (
    <div>
         <HomeNavBar/>
      <div className="" style={{ margin: "20px" }}>
        <Card style={{border: "1px solid #d5c6c675"}}>
          <Card.Body>
            <div className="row">
              <div className="col-md-9">
                <HomeCard HomecardInformation={HomecardInformation} handleItemClick={handleItemClick} />
              </div>

              <div className="col-md-3">
                <Card>
                  <Card.Header>
                    <FontAwesomeIcon icon={faCog} fixedWidth />
                    সেটিংস
                  </Card.Header>
                  <p style={{ margin: "20px" }}>
                    প্রথমে ফ্ল্যাট এবং বিল্ডিং বাসিন্দাদের তথ্য যোগ করুন
                  </p>
                  <Card.Body>
                    <HomeCard2 HomecardInformation={HomecardInformation2} handleItemClick={handleItemClick} />
                  </Card.Body>
                </Card>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}

export default Admindashboard
import axios from "axios";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Banner from "../Component/Banner";

const FlatInformation = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  // const handleItemClick = () => {
  //   // Navigate to the specified route
  //   navigate("/locationform");
  // }

  const handleItemClick2 = () => {
    // Navigate to the specified route
    navigate("/admindashboard");
  };
  const [validated, setValidated] = useState(false);

  const validationSchema = Yup.object().shape({
    location: Yup.string().required("Location is required"),
    address: Yup.string().required("address is required"),
    city: Yup.string().required("City is required"),
    devision: Yup.string().required("devision is required"),
    post_code: Yup.string().required("post_code is required"),
    country: Yup.string().required("counrty is required"),
  });
  const handleSubmit = (values, actions) => {
    axios
      .post("http://127.0.0.1:8000/api/location", {
        location: values.location,
        address: values.address,
        city: values.city,
        devision: values.devision,
        post_code: values.post_code,
        country: values.country,
      })
      .then((response) => {
        if (response.status === 200) {
          alert("Submitted Successfully");
          actions.resetForm();
          handleClose();
        } // Handle success response
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetch("http://127.0.0.1:8000/api/location")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setData(data);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      <Banner
        banner={"ফ্ল্যাটের বিবরন"}
        handleItemClick={handleShow}
        handleItemClick2={handleItemClick2}
        pressbutton={"নতুন  ফ্ল্যাট যোগ করুন "}
        backbutton={"পিছনে ফিরে যান "}
      />

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>প্রজেক্ট লোকেশন</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              city: "",
              address: "",
              devision: "",
              location: "",
              post_code: "",
              country: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              touched,
              handleSubmit,
              handleChange,
              handleBlur,
              values,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <div class="accordion" id="accordionPanelsStayOpenExample">
                  <div class="accordion-item">
                    <h2
                      class="accordion-header"
                      id="panelsStayOpen-headingOne"
                    ></h2>
                    <div
                      id="panelsStayOpen-collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingOne"
                    >
                      <div class="accordion-body">
                        <div className="row">
                          <div className="col-md-4">
                            <Form.Group controlId="validationCustom03">
                              <Form.Label>বিল্ডিং লোকেশান</Form.Label>

                              {/* <Form.Control
                        type="text"
                        value={values.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="city"
                        placeholder=""
                        required >
                            
                        </Form.Control> */}
                              <select
                                class="form-control"
                                type="text"
                                value={values.city}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="city"
                                placeholder=""
                                required
                                id="exampleFormControlSelect1"
                              >
                                {data.map((item, index) => (
                                  <option key={item.id}>{item.location}</option>
                                ))}
                              </select>

                              {touched.city && errors.city && (
                                <div style={{ color: "red" }}>
                                  {errors.city}
                                </div>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-md-4">
                            <Form.Group controlId="validationCustom03">
                              <Form.Label>ফ্ল্যাট নং</Form.Label>
                              <Form.Control
                                type="text"
                                value={values.location}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="location"
                                placeholder=""
                                required
                              />
                              {touched.location && errors.location && (
                                <div style={{ color: "red" }}>
                                  {errors.location}
                                </div>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-md-4">
                            <Form.Group controlId="validationCustom03">
                              <Form.Label>ভাড়া</Form.Label>
                              <Form.Control
                                type="text"
                                value={values.location}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="vara"
                                placeholder=""
                                required
                              />
                              {touched.location && errors.location && (
                                <div style={{ color: "red" }}>
                                  {errors.location}
                                </div>
                              )}
                            </Form.Group>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <Form.Group controlId="validationCustom03">
                              <Form.Label>মিটার টাইপ</Form.Label>
                              <Form.Control
                                type="text"
                                value={values.location}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="location"
                                placeholder=""
                                required
                              />
                              {touched.location && errors.location && (
                                <div style={{ color: "red" }}>
                                  {errors.location}
                                </div>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-md-6">
                            <Form.Group controlId="validationCustom03">
                              <Form.Label>গ্যাস বিল</Form.Label>
                              <Form.Control
                                type="text"
                                value={values.location}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="location"
                                placeholder=""
                                required
                              />
                              {touched.location && errors.location && (
                                <div style={{ color: "red" }}>
                                  {errors.location}
                                </div>
                              )}
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwo"
                      >
                        ফ্ল্যাট
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingTwo"
                    >
                      <div class="accordion-body">
                        <div className="row">
                          <div className="col-md-4">
                            <Form.Group controlId="validationCustom03">
                              <Form.Label>ফ্ল্যাট টাইপ</Form.Label>

                              {/* <Form.Control
                        type="text"
                        value={values.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="city"
                        placeholder=""
                        required >
                            
                        </Form.Control> */}
                              <select
                                class="form-control"
                                type="text"
                                value={values.city}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="city"
                                placeholder=""
                                required
                                id="exampleFormControlSelect1"
                              >
                                {data.map((item, index) => (
                                  <option key={item.id}>{item.location}</option>
                                ))}
                              </select>

                              {touched.city && errors.city && (
                                <div style={{ color: "red" }}>
                                  {errors.city}
                                </div>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-md-4">
                            <Form.Group controlId="validationCustom03">
                              <Form.Label>
                                ফ্ল্যাট সাইজ (স্কায়ার ফিট)
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={values.location}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="location"
                                placeholder=""
                                required
                              />
                              {touched.location && errors.location && (
                                <div style={{ color: "red" }}>
                                  {errors.location}
                                </div>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-md-4">
                            <Form.Group controlId="validationCustom03">
                              <Form.Label>ফ্ল্যাট প্রাইস</Form.Label>
                              <Form.Control
                                type="text"
                                value={values.location}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="vara"
                                placeholder=""
                                required
                              />
                              {touched.location && errors.location && (
                                <div style={{ color: "red" }}>
                                  {errors.location}
                                </div>
                              )}
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2
                      class="accordion-header"
                      id="panelsStayOpen-headingThree"
                    >
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseThree"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        অন্যান্য তথ্য
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingThree"
                    >
                      <div class="accordion-body">
                        <div className="row">
                          <div className="col-md-4">
                            <Form.Group controlId="validationCustom03">
                              <Form.Label>মিটার নাম</Form.Label>
                              <Form.Control
                                type="text"
                                value={values.location}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="vara"
                                placeholder=""
                                required
                              />
                              {touched.location && errors.location && (
                                <div style={{ color: "red" }}>
                                  {errors.location}
                                </div>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-md-4">
                            <Form.Group controlId="validationCustom03">
                              <Form.Label>জেনারেল মিটার নাম্বার</Form.Label>
                              <Form.Control
                                type="text"
                                value={values.location}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="location"
                                placeholder=""
                                required
                              />
                              {touched.location && errors.location && (
                                <div style={{ color: "red" }}>
                                  {errors.location}
                                </div>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-md-4">
                            <Form.Group controlId="validationCustom03">
                              <Form.Label>মেইন মিটার নাম্বার</Form.Label>
                              <Form.Control
                                type="text"
                                value={values.location}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="vara"
                                placeholder=""
                                required
                              />
                              {touched.location && errors.location && (
                                <div style={{ color: "red" }}>
                                  {errors.location}
                                </div>
                              )}
                            </Form.Group>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              class="btn-group"
                              role="group"
                              aria-label="Basic radio toggle button group"
                            >
                              <div
                                className=""
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: "10px",
                                }}
                              >
                                <h6>জেনারেটর</h6>
                              </div>
                              <div className="" style={{ margin: "10px" }}>
                                <input
                                  type="radio"
                                  class="btn-check"
                                  name="btnradio2"
                                  id="btnradio1"
                                  autocomplete="off"
                                  checked
                                />
                                <label
                                  style={{ margin: "10px" }}
                                  class="btn btn-outline-primary"
                                  for="btnradio1"
                                  
                                >
                                 Yes
                                </label>

                                <input
                                  type="radio"
                                  class="btn-check"
                                  name="btnradio2"
                                  id="btnradio2"
                                  autocomplete="off"
                                />
                                <label
                                  style={{ margin: "10px" }}
                                  class="btn btn-outline-primary"
                                  for="btnradio2"
                                >
                                 No
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              class="btn-group"
                              role="group"
                              aria-label="Basic radio toggle button group"
                            >
                              <div
                                className=""
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: "10px",
                                }}
                              >
                                <h6>পানির মিটার</h6>
                              </div>
                              <div className="" style={{ margin: "10px" }}>
                                <input
                                  type="radio"
                                  class="btn-check"
                                  name="btnradio3"
                                  id="btnradio3"
                                  autocomplete="off"
                                  checked
                                />
                                <label
                                  style={{ margin: "10px" }}
                                  class="btn btn-outline-primary"
                                  for="btnradio3"
                                >
                                  Yes
                                </label>

                                <input
                                  type="radio"
                                  class="btn-check"
                                  name="btnradio3"
                                  id="btnradio4"
                                  autocomplete=""
                                />
                                <label
                                  style={{ margin: "10px" }}
                                  class="btn btn-outline-primary"
                                  for="btnradio4"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Modal.Footer
                  style={{ justifyContent: "space-between", borderTop: "none" }}
                >
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" type="submit">
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FlatInformation;

import React from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from "../assets/db2.png";
import logo2 from "../assets/abh.png";
import LoginCard from '../Component/LoginCard';
import Footer from '../Component/Footer';
import { useNavigate } from 'react-router-dom';



const FeatureInformation=[{
  img: require('../assets/note.png'),
  title:"বিলিং",
  text:[" অ্যাপার্টমেন্টের সার্ভিস চার্জসহ যে কোন আয়ের বিল তৈরি",'প্রতি মাসের ভাড়া, বিদ্যুৎ/পানি/গ্যাস সহ ইউটিলিটি বিল তৈরি','বিভিন্ন খরচের বিল (স্টাফের বেতন, কমন বিদ্যুৎ বিল ইত্যাদি) তৈরি']
},
{
  img: require('../assets/account.jpg'),
  title:"একাউন্টিং",
  text:["ব্যাংক এবং ক্যাশ ব্যালেন্স চেক",'বকেয়া রিপোর্ট',' খরচের জিএল রিপোর্ট',"ব্যাল্যেন্স শিট সহ মাসিক আয় ব্যয় এর হিসাব"]
},
{
  img: require('../assets/sms.png'),
  title:"নোটিফিকেশন",
  text:["বিল্ডিং বাসিন্দাদের SMS নোটিফিকেশন",'ইমেইল নোটিফিকেশন',"ফাইল প্রিন্ট"]
},

{
  img: require('../assets/maintenance.jpg'),
  title:"মেইন্টেনেন্স শিডিউল",
  text:[" যে কোন মেইন্টেনেন্সের (লিফট, জেনারেটর ইত্যাদি সার্ভিসিং এর) জন্য শিডিউল তৈরি",'শিডিউল এর নির্দিষ্ট দিন পূর্বে ইমেইল এবং SMS নোটিফিকেশন']
},
{
  img: require('../assets/meeting.png'),
  title:"অ্যাসোসিয়েশন কমিটি",
  text:["অ্যাসোসিয়েশন কমিটি তৈরি",'মিটিং মিনিটস আপলোড',' মিটিং এর পূর্বে কমিটি সদস্যদের SMS নোটিফিকেশন',]
},
{
  img: require('../assets/occupant.jpg'),
  title:"বিল্ডিং বাসিন্দার পোর্টাল",
  text:["সার্ভিস রিকোয়েস্ট অথবা কমপ্লেইন",' নিজেদের বিল এবং পেমেন্টের তথ্য দেখা']
}]

const Home = () => {


const navigate = useNavigate();

  const handleClick = () => {
    // Navigate to the specified route
    navigate('/singin');
  };
  const handleClick2 = () => {
    // Navigate to the specified route
    navigate('/login');
  };
  return (
<>
<div className="">
<div style={{ padding: "0px",background:"#356cb0" }} className="">
<div className="container">
    <div className="row">
        <div className="col-md-6">
          <img  style={{ height: "50px" }} src={logo} alt="" />

        </div>
        <div className="col-md-6" style={{textAlign:"end"}}>
      <Button variant="outline-success" onClick={handleClick2} style={{margin:"10px",color:"white"}}>LOGIN</Button>
      <Button variant="outline-success" onClick={handleClick} style={{margin:"10px",color:"white"}}>PRICING</Button>

        </div>
          

      </div>

      </div>
      </div>
<div className="backgroundimage">
      {/* <img   src={logo2} alt="" /> */}
      <div className="overlay" >
        <div className=""></div>
        <div className="singinButon">
      <Button variant="outline-success" onClick={handleClick}  style={{margin:"10px",color:""}}>সাইন আপ করুন ফ্রি</Button>

        </div>

      </div>
      </div>
      </div>
      
        <h1 style={{textAlign:"center",margin:"30px 0px",borderBottom:"1px solid whitesmoke"}}>উপভোগ করুন পরিপূর্ণ ডিজিটাল এক্সপিরিয়েন্স</h1>
        <LoginCard FeatureInformation={FeatureInformation}/>
        <Footer/>
      
   
      
  </>
  )
}

export default Home
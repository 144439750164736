import React from 'react'
import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";
import HomeCard from '../Component/HomeCard';
import {  faPrint } from "@fortawesome/free-solid-svg-icons";
import ReportCArd from '../Component/ReportCArd';



const report=[{
    icon: faPrint,
    title: "রিপোর্ট",
    text: "বিল প্রিন্ট",
    value: "0",
  },]
const Billing = () => {
  return (
    <div style={{ margin: "20px" }} className="">
    <Card>
      <Card.Body style={{ boxShadow: "0 0 6px #a7a7a7",backgroundColor:"#514c47" }}>
        <Nav className="me-auto">
          <Nav.Link style={{ color: "white" }} href="/">
        <h3> বিলিং</h3>  
          </Nav.Link>
        </Nav>
      </Card.Body>
    </Card>


    <div className="row">
        <div className="col-md-9">
        <Card className="" style={{ marginTop: "20px" }}>
        <Card.Header
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "15px",
            backgroundColor: "#fff",
          }}
          as="h5"
        >
          {" "}
          <Card.Title style={{ fontSize: "17px", fontWeight: "600" }}>
          বিলিং
          </Card.Title>
        </Card.Header>
        <a
                href=""
                style={{
                  textDecoration: "none",
                  color: "black",
                  textAlign: "center",
                }}
              >
        <div className="row">
          <div className="col-md-6">
        <Card style={{ margin: "20px" }}>
        <Card.Header
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "15px",
            backgroundColor: "#fff",
          }}
          as="h5"
        >
          {" "}
          <p style={{ fontSize: "16px",color: "#587b86",fontWeight:"bold" }}> ইউটিলিটি সহ মাসিক বিল</p>
        </Card.Header>
        <Card.Body>
         
            <div className="">
              
           
                <p style={{ }}>অফিস/বাসা/দোকানের ভাড়া, সার্ভিস চার্জ, পানির বিল, বিদ্যুৎ বিল সহ অন্যান্য বিল তৈরি</p>
                <p>1</p>
            </div>
          
        </Card.Body>
        </Card>
        </div>
        <div className="col-md-6">
        <Card style={{ margin: "20px" }}>
        <Card.Header
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "15px",
            backgroundColor: "#fff",
          }}
          as="h5"
        >
          {" "}
          <p style={{ fontSize: "16px",color: "#587b86",fontWeight:"bold" }}>ব্যয়ের বিল </p>
        </Card.Header>
        <Card.Body>
         
            <div className="">
              
                
                <p style={{ }}>কর্মচারীদের বেতন,বোনাস, কনভেন্স বিল সহ অ্যাপার্টমেন্টের যাবতীয় ব্যয়ের বিল তৈরি</p>
                <p>2</p>
            </div>
        
          
        </Card.Body>
        </Card>
        </div>
        </div>
        </a>
      </Card>
        </div>
        <div className="col-md-3">
            <ReportCArd HomecardInformation={report}/>
        </div>
    </div>
   
  </div>
  )
}

export default Billing
import React from 'react'
import Banner from '../Component/Banner'

const BuyBill = () => {
  return (
    <div>
      <Banner banner={"ব্যয়ের বিল"} pressbutton={'ব্যয়ের বিল তৈরি'} backbutton={'পিছনে ফিরে যান'}/>
    </div>
  )
}

export default BuyBill
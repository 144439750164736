import React from "react";

function Footer() {
  return (
    <footer>
      <div className="container-fluid">
        <div className="row">
          <div className="footer-container">
            <div className="container">
              <div className="row">
                <div className="col-md-4" style={{ textAlign: "center" }}>
                  <div className="footer-content">
                    <h4>Address</h4>
                    <p>সেকেন্ড ফ্লোর</p>
                    <p>বাড়ি# B1, রোড# 5/9, সেক্টর# 5</p>
                    <p>লালমাটিয়া , ঢাকা-১২০৭, বাংলাদেশ</p>
                    <p>Email: info@techknowgram.com</p>
                  </div>
                </div>
                <div className="col-md-4" style={{ textAlign: "center" }}>
                  <div className="footer-content">
                    <h4>Others</h4>
                    <p> Privacy Policy</p>
                    <p>Terms and Conditions</p>
                    <p>Refund Policy</p>
                    <p>Blog</p>
                  </div>
                </div>
                <div className="col-md-4" style={{ textAlign: "center" }}>
                  <div className="footer-content">
                    <h4>Links</h4>
                    <ul
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <li>
                        <a href="/" style={{ margin: "10px" }}>
                          <i class="fab fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/about" style={{ margin: "10px" }}>
                          <i class="fab fa-youtube-square"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/services" style={{ margin: "10px" }}>
                          <i class="fab fa-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>
            &copy; {new Date().getFullYear()} Power by TechKnowGram Limited .
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

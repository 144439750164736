import axios from "axios";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Banner from "../Component/Banner";

const BuildingLocation = ({access_token}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  // const handleItemClick = () => {
  //   // Navigate to the specified route
  //   navigate("/locationform");
  // }

  const handleItemClick2 = () => {
    // Navigate to the specified route
    navigate("/admindashboard");
  };
  const [validated, setValidated] = useState(false);

  const validationSchema = Yup.object().shape({
    location: Yup.string().required("Location is required"),
    address: Yup.string().required("address is required"),
    city: Yup.string().required("City is required"),
    devision: Yup.string().required("devision is required"),
    postal_code: Yup.string().required("postal_code is required"),
    country: Yup.string().required("counrty is required"),
  });
  const handleSubmit = (values, actions) => {

    axios
      .post("https://api.shopnobash.com/api/project-locations", {
        location: values.location,
        address: values.address,
        city: values.city,
        devision: values.devision,
        postal_code: values.postal_code,
        country: values.country,
        district: values.district,
      }, {
        headers: {
          'Authorization': `Bearer ${access_token}`
        }
      })
      .then((response) => {
        if (response.status === 200) {
          alert("Submitted Successfully");
          actions.resetForm();
          handleClose();
        } // Handle success response
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
      
      axios.get("https://api.shopnobash.com/api/project-locations/own", {
        headers: {
          'Authorization': `Bearer ${access_token}`
        }
      }).then((response) => {
        if (response.status === 200) {
        } // Handle success response
        console.log(response);
        setData(response.data.data)
      })
      .catch(function (error) {
        console.log(error);
      });;
  };

  return (
    <div>
      <Banner
        handleItemClick={handleShow}
        banner={"প্রজেক্ট লোকেশন"}
        pressbutton={"প্রজেক্ট লোকেশন যোগ করুন"}
        backbutton={"পিছনে ফিরে যান "}
        handleItemClick2={handleItemClick2}
      />

      <Card.Header style={{ margin: "20px" }}>
        <h3> লোকেশন</h3>
      </Card.Header>

      <table
        style={{ margin: "20px" }}
        id="dtBasicExample"
        class="table table-striped table-bordered table-sm"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th class="th-sm">Serial No</th>
            <th class="th-sm">প্রজেক্ট লোকেশন</th>
            <th class="th-sm">ঠিকানা</th>
            <th class="th-sm">শহর</th>
            <th class="th-sm">বিভাগ</th>
            <th class="th-sm">দেশ</th>
            <th class="th-sm">district</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.location}</td>
              <td>{item.address}</td>
              <td>{item.city}</td>
              <td>{item.devision}</td>
              <td>{item.country}</td>
              <td>{item.district}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>প্রজেক্ট লোকেশন</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              city: "",
              address: "",
              devision: "",
              location: "",
              postal_code: "",
              country: "",
              district:""
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              touched,
              handleSubmit,
              handleChange,
              handleBlur,
              values,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-4">
                    <Form.Group controlId="validationCustom03">
                      <Form.Label>প্রজেক্ট লোকেশন</Form.Label>
                      <Form.Control
                        type="text"
                        value={values.location}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="location"
                        placeholder="location"
                        required
                      />
                      {touched.location && errors.location && (
                        <div style={{ color: "red" }}>{errors.location}</div>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-md-4">
                    <Form.Group controlId="validationCustom03">
                      <Form.Label>ঠিকানা </Form.Label>
                      <Form.Control
                        type="text"
                        value={values.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="address"
                        placeholder=""
                        required
                      />
                       {touched.address && errors.address && (
                        <div style={{ color: "red" }}>{errors.address}</div>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-md-4">
                    <Form.Group controlId="validationCustom03">
                      <Form.Label>জেলা</Form.Label>
                      <Form.Control
                        type="text"
                        value={values.district}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="district"
                        placeholder=""
                        required
                      />
                       {touched.district && errors.district && (
                        <div style={{ color: "red" }}>{errors.district}</div>
                      )}
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <Form.Group controlId="validationCustom03">
                      <Form.Label>শহর</Form.Label>
                      <Form.Control
                        type="text"
                        value={values.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="city"
                        placeholder=""
                        required
                      />
                     {touched.city && errors.city && (
                        <div style={{ color: "red" }}>{errors.city}</div>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <Form.Group controlId="validationCustom03">
                      <Form.Label>বিভাগ </Form.Label>
                      <Form.Control
                        type="text"
                        value={values.devision}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="devision"
                        placeholder=""
                        required
                      />
                      {touched.devision && errors.devision && (
                        <div style={{ color: "red" }}>{errors.devision}</div>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <Form.Group controlId="validationCustom03">
                      <Form.Label>পোস্টাল কোড </Form.Label>
                      <Form.Control
                        type="text"
                        value={values.postal_code}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="postal_code"
                        placeholder=""
                        required
                      />
                      {touched.postal_code && errors.postal_code && (
                        <div style={{ color: "red" }}>{errors.postal_code}</div>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <Form.Group controlId="validationCustom03">
                      <Form.Label>দেশ </Form.Label>
                      <Form.Control
                        type="text"
                        value={values.country}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="country"
                        placeholder=""
                        required
                      />
                     {touched.country && errors.country && (
                        <div style={{ color: "red" }}>{errors.country}</div>
                      )}
                    </Form.Group>
                  </div>
                </div>
                <Modal.Footer style={{ justifyContent: "space-between",borderTop:"none" }}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        
      </Modal>
    </div>
  );
};

export default BuildingLocation;

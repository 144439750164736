import React from 'react';
import Button from "react-bootstrap/Button";
import { useNavigate } from 'react-router-dom';
import logo from "../assets/db2.png";


const HomeHaderNav = () => {

    const navigate = useNavigate();

    const loginhandele =()=>{
        navigate('/login')
    }
    const pricehandele =()=>{
        navigate('/singin')
    }
    const homebutton =()=>{
        navigate('/')
    }
  return (
    <div><div style={{ padding: "0px", background: "#356cb0" }} className="">
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <img onClick={homebutton}style={{ height: "50px" }} src={logo} alt="" />
        </div>
        <div className="col-md-6" style={{ textAlign: "end" }}>
          <Button onClick={loginhandele}
            variant="outline-success"
            style={{ margin: "10px", color: "white" }}
          >
            LOGIN
          </Button>
          <Button onClick={pricehandele}
            variant="outline-success"
            style={{ margin: "10px", color: "white" }}
          >
            PRICING
          </Button>
        </div>
      </div>
    </div>
  </div></div>
  )
}

export default HomeHaderNav
import React from "react";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";

const LoginCard = ({ FeatureInformation }) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-1">
          
        </div>
        <div className="col-md-10">
          <div className="row">
        <CardGroup>
          {FeatureInformation.map(({ img, text, title }) => (
            <div className="col-md-4">
              <Card style={{margin:"0px 15px",border:"none"}}>
                <Card.Img
                  variant="top"
                  style={{
                    maxWidth: "30%",
                    height: "auto",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={img}
                />
                <Card.Body>
                  <Card.Title style={{textAlign:"center"}}>{title}</Card.Title>
                  <Card.Text style={{padding:"20px"}}>
                   

                    {text.map(item=> <li>{item}</li>)}
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          ))}
        </CardGroup>
        </div>
        </div>
        {/* <div className="col-md-1">
          
        </div> */}
      
      </div>
    </div>
  );
};

export default LoginCard;

import React from "react";

import axios from "axios";
import { Formik } from "formik";
import { useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import Footer from "../Component/Footer";
import HomeHaderNav from "../Component/HomeHaderNav";

const Loginverify = () => {

    const navigate = useNavigate();

    const loginsuccessfull =()=>{
        navigate('/admindashboard')
    }
    const validationSchema = Yup.object().shape({
        password: Yup.string().required("password is required"),
        email: Yup.string().email('Invalid email').required('Email is required'),
        
      });
    
      const handleSubmit = (values, actions) => {
        axios
          .post("https://api.shopnobash.com/api/auth/login", {
            password: values.password,
            email: values.email,
          })
          .then((response) => {
            if (response.status === 200) {
                localStorage.setItem("access_token", response.data.data.access_token);
                loginsuccessfull();
              actions.resetForm();
              alert('logined')

            } // Handle success response
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
            // alert(error.response.data.message);
          });
      };
  return (
    <div>
      <HomeHaderNav/>
      <div className="registration">
        <div class="signup-form ">
          <Formik
            initialValues={{
              password: "",
              email: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              touched,
              handleSubmit,
              handleChange,
              handleBlur,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <h2>Login</h2>
                
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    name="email"
                    placeholder="Email "
                    required="required"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.email && errors.email && (
                    <div style={{ color: "red" }}>{errors.email}</div>
                  )}
                </div>
                <div class="form-group">
                  <input
                    type="password"
                    class="form-control"
                    name="password"
                    placeholder="password"
                    required="required"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.password && errors.password && (
                    <div style={{ color: "red" }}>{errors.password}</div>
                  )}
                </div>
                <div class="form-group">
                  <button
                    type="submit"
                    class="btn btn-success btn-lg btn-block"
                  >
                    Login Now
                  </button>
                  
                </div>
              </form>
            )}
          </Formik>

         
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Loginverify;

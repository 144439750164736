import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";


const Banner = (
  { banner,handleItemClick,handleItemClick2,backbutton,pressbutton }
  ) => {

  console.log("pressbutton", pressbutton);
  
  
  return (
    <div>
      <Card>
        <Card.Body
          style={{
            boxShadow: "0 0 6px #a7a7a7",
            backgroundColor: "#514c47",
          }}
        >
          <div className="row">
            <div className="col-md-6">
              <h3 style={{ fontSize: "18px", color: "white", padding: "10px" }}>
                {banner}
              </h3>
            </div>
            <div
              className="col-md-6"
              style={{
                display: "flex",

                justifyContent: "end",
                /* padding: 10px; */
                alignItems: "center",
              }}
            >
              <Button onClick={handleItemClick2} variant="info" style={{marginRight:"10px"}}>{backbutton} </Button>
          {pressbutton && pressbutton.length ? (<Button  onClick={handleItemClick}variant="success" style={{marginRight:"10px"}}>{pressbutton}</Button> ):null}    

            </div>
          </div>
          {/* <Button bsStyle="info">Success</Button> */}
        </Card.Body>
      </Card>
      
    </div>
  );
};

export default Banner;

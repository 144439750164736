import React from 'react'
import HomeNavBar from "../Component/HomeNavBar"
import Card from "react-bootstrap/Card";


const Services = () => {
  return (
    <div>
      <HomeNavBar/>
      <Card className="" style={{ margin: "20px" }}>
        <Card.Header
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "15px",
            backgroundColor: "#fff",
          }}
          as="h5"
        >
          {" "}
          <Card.Title style={{ fontSize: "17px", fontWeight: "600" }}>
          ব্যাচ প্রসেস
          </Card.Title>
        </Card.Header>
        <a
                href=""
                style={{
                  textDecoration: "none",
                  color: "black",
                  textAlign: "center",
                }}
              >
        <div className="row">
          <div className="col-md-4">
        <Card style={{ margin: "20px" }}>
        <Card.Header
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "15px",
            backgroundColor: "#fff",
          }}
          as="h5"
        >
          {" "}
          <p style={{ fontSize: "16px",color: "#587b86",fontWeight:"bold" }}>বকেয়া SMS নোটিফিকেশন</p>
        </Card.Header>
        <Card.Body>
         
            <div className="">
              
                
                <p style={{ }}>সবাইকে এক ক্লিকে বকেয়ার নোটিফিকেশন পাঠান</p>
            </div>
          
        </Card.Body>
        </Card>
        </div>
        </div>
        </a>
      </Card>
    </div>
  )
}

export default Services
import React from "react";
import HomeNavBar from "../Component/HomeNavBar";
import Card from "react-bootstrap/Card";

const Dashboard = () => {
  return (
    <div>
      <HomeNavBar />

      <Card className="" style={{ margin: "20px" }}>
        <Card.Header
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "15px",
            backgroundColor: "#fff",
          }}
          as="h5"
        >
          {" "}
          <Card.Title style={{ fontSize: "17px", fontWeight: "600" }}>
            দৈনন্দিন এক্টিভিটিস
          </Card.Title>
        </Card.Header>

        <Card.Body>
          <div className="row">
            <div className="col-md-3">
              <a
                href=""
                style={{
                  textDecoration: "none",
                  color: "black",
                  textAlign: "center",
                }}
              >
                <h5 style={{ fontSize: "2.1rem", color: "#587b86" }}>0</h5>
                <p style={{ fontSize: "14px" }}>আজকে ইনকাম বিল তৈরি হয়েছে</p>
              </a>
            </div>

            <div className="col-md-3">
              <a
                href=""
                style={{
                  textDecoration: "none",
                  color: "black",
                  textAlign: "center",
                }}
              >
                <h5 style={{ fontSize: "2.1rem", color: "#587b86" }}>0</h5>
                <p style={{ fontSize: "14px" }}>
                  আজকে ব্যয়ের বিল তৈরি হয়েছে{" "}
                </p>
              </a>
            </div>
            <div className="col-md-3">
              <a
                href=""
                style={{
                  textDecoration: "none",
                  color: "black",
                  textAlign: "center",
                }}
              >
                <h5 style={{ fontSize: "2.1rem", color: "#587b86" }}>0</h5>
                <p style={{ fontSize: "14px" }}>আজকের নগদ গ্রহণ </p>
              </a>
            </div>
            <div className="col-md-3">
              <a
                href=""
                style={{
                  textDecoration: "none",
                  color: "black",
                  textAlign: "center",
                }}
              >
                <h5 style={{ fontSize: "2.1rem", color: "#587b86" }}>0</h5>
                <p style={{ fontSize: "14px" }}>আজকের নগদ গ্রহণ</p>
              </a>
            </div>
          </div>
        </Card.Body>
      </Card>
      <div className="row">
        <div className="col-md-6">
          <Card className="" style={{ margin: "20px" }}>
            <Card.Header
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "15px",
                backgroundColor: "#fff",
              }}
              as="h5"
            >
              {" "}
              <Card.Title style={{ fontSize: "17px", fontWeight: "600" }}>
                ফিনান্সিয়াল স্ট্যাটাস
              </Card.Title>
            </Card.Header>

            <Card.Body>
              <div className="row">
                <div className="col-md-4">
                  <a
                    href=""
                    style={{
                      textDecoration: "none",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    <h5 style={{ fontSize: "2.1rem", color: "#587b86" }}>0</h5>
                    <p style={{ fontSize: "14px" }}>বর্তমান মাসের আয়</p>
                  </a>
                </div>

                <div className="col-md-4">
                  <a
                    href=""
                    style={{
                      textDecoration: "none",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    <h5 style={{ fontSize: "2.1rem", color: "#587b86" }}>0</h5>
                    <p style={{ fontSize: "14px" }}>বর্তমান মাসের ব্যয় </p>
                  </a>
                </div>
                <div className="col-md-4">
                  <a
                    href=""
                    style={{
                      textDecoration: "none",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    <h5 style={{ fontSize: "2.1rem", color: "#587b86" }}>
                      27000
                    </h5>
                    <p style={{ fontSize: "14px" }}>বর্তমান বকেয়া </p>
                  </a>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>

        <div className="col-md-6">
          <Card className="" style={{ margin: "20px" }}>
            <Card.Header
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "15px",
                backgroundColor: "#fff",
              }}
              as="h5"
            >
              {" "}
              <Card.Title style={{ fontSize: "17px", fontWeight: "600" }}>
                বিল্ডিং সামারি
              </Card.Title>
            </Card.Header>

            <Card.Body>
              <div className="row">
                <div className="col-md-4">
                  <a
                    href=""
                    style={{
                      textDecoration: "none",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    <h5 style={{ fontSize: "2.1rem", color: "#587b86" }}>2</h5>
                    <p style={{ fontSize: "14px" }}>প্রজেক্ট লোকেশান</p>
                  </a>
                </div>

                <div className="col-md-4">
                  <a
                    href=""
                    style={{
                      textDecoration: "none",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    <h5 style={{ fontSize: "2.1rem", color: "#587b86" }}>4</h5>
                    <p style={{ fontSize: "14px" }}>ফ্ল্যাট/বাসার সংখ্যা</p>
                  </a>
                </div>
                <div className="col-md-4">
                  <a
                    href=""
                    style={{
                      textDecoration: "none",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    <h5 style={{ fontSize: "2.1rem", color: "#587b86" }}>0</h5>
                    <p style={{ fontSize: "14px" }}>বিল্ডিং বাসিন্দা </p>
                  </a>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import React, { useState } from "react";


function Singin({pakageInformation,handleClick}) {
  return (
    <>
      <div className="container">

    <div className="row">
      
      {pakageInformation.map(({feature,amount,list,statuss, Path}) =>(
        <div className="col-md-4">
<Card className="text-center">
      <Card.Header>{feature}</Card.Header>
      <Card.Body>
        <Card.Title >{amount}</Card.Title>
        <Card.Text style={{textAlign:"start",margin:"15px 20px"}}>
        {statuss}
        <br />
        {list.map(item=>(
 <li>{item}</li>
        ))}
       
        </Card.Text>
        <Button variant="primary" onClick={()=> handleClick(Path)}>GET STARTED</Button>
      </Card.Body>
    </Card>
    </div>
    ))}
    
      </div>
    </div>
    
    </>
  );
}

export default Singin;
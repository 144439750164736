import axios from "axios";
import { Formik } from "formik";
import React from "react";
import { useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import Footer from "../Component/Footer";
import HomeHaderNav from "../Component/HomeHaderNav";


const RegistrationPage = () => {


  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/loginverify');
  };
  const validationSchema = Yup.object().shape({
    user_name: Yup.string().required("user name is required"),
    name: Yup.string().required("name is required"),
    email: Yup.string().email('Invalid email').required('Email is required'),
    
  });

  const handleSubmit = (values, actions) => {
    axios
      .post("https://api.shopnobash.com/api/register/landlord", {
        user_name: values.user_name,
        name: values.name,
        email: values.email,
      })
      .then((response) => {
        if (response.status === 200) {
          alert("Submitted Successfully");
          actions.resetForm();
        } // Handle success response
        console.log(response);
      })
      .catch(function (error) {
        // console.log(error);
        alert(error.response.data.message);
      });
  };
  return (
    <div>
      <HomeHaderNav/>

      <div className="registration">
        <div class="signup-form ">
          <Formik
            initialValues={{
              user_name: "",
              name: "",
              email: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              touched,
              handleSubmit,
              handleChange,
              handleBlur,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <h2>Register</h2>
                <p class="hint-text">
                  Create your account. It's free and only takes a minute.
                </p>
                <div class="form-group"></div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    name="name"
                    placeholder="Name"
                    required="required"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.name && errors.name && (
                    <div style={{ color: "red" }}>{errors.name}</div>
                  )}
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    name="user_name"
                    placeholder="User Name"
                    required="required"
                    value={values.user_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.user_name && errors.user_name && (
                    <div style={{ color: "red" }}>{errors.user_name}</div>
                  )}
                </div>
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    name="email"
                    placeholder="Email "
                    required="required"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.email && errors.email && (
                    <div style={{ color: "red" }}>{errors.email}</div>
                  )}
                </div>
                <div class="form-group">
                  <label class="checkbox-inline">
                    <input type="checkbox" required="required" /> I accept the{" "}
                    <a href="#">Terms of Use</a> &amp;{" "}
                    <a href="#">Privacy Policy</a>
                  </label>
                </div>
                <div class="form-group">
                  <button
                    type="submit"
                    class="btn btn-success btn-lg btn-block"
                  >
                    Register Now
                  </button>
                  <div class="text-center">
            Already have an account? <p onClick={handleClick} style={{color:"#5cb85c",cursor:"pointer"}}> Sign in</p> 
          </div>
                </div>
              </form>
            )}
          </Formik>

         
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RegistrationPage;

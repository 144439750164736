import React, {useState, useEffect} from 'react'
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const HomeCard2 = ({HomecardInformation,handleItemClick}) => {

  const [locations, setLocations] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetch("http://127.0.0.1:8000/api/location")
      .then((response) => response.json())
      .then((data) => setLocations(data))
      .catch((error) => console.log(error));
  };
  return (
    <div className="row">
        
          {HomecardInformation.map(({ icon, title, value,path }) => (
            <div className="col-md-6 ">
              <a href="" className="underline">
            <Card onClick={() => handleItemClick(path)} className="box" style={{marginTop:"20px",border:"1px solid #d5c6c675"}}>
              <Card.Header
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "15px",
                  backgroundColor:"rgb(255 255 255 / 0%)"
                }}
                as="h5"
              >
                {" "}
                <Card.Title style={{fontSize:"13px",fontWeight:"600",color:"#587b86"}}>{title}</Card.Title>
                <FontAwesomeIcon style={{
                      backgroundColor: "#587b86",
                      padding: "6px",
                      borderRadius: " 6px",
                      color: "white",
                    }} icon={icon} fixedWidth />
              </Card.Header>

              <Card.Body>
                <h5>{path && path=='/location' ? locations.length : value}</h5>
              </Card.Body>
            </Card>
            </a>
        </div>

          ))}
      </div>
  )
}

export default HomeCard2
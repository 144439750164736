import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import "./index.css";

const HomeCard = ({ HomecardInformation, handleItemClick }) => {
  return (
    <div>
      <div className="row">
        {HomecardInformation.map(({ icon, text, title, value, path }, key) => (
          <div key={key} className="col-md-4 ">
            <Card
              onClick={() => handleItemClick(path)}
              className="box underline"
              style={{ marginTop: "20px",  }}
            >
              <Card.Header
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "15px",
                  backgroundColor: "rgb(255 255 255 / 0%)",
                  
                }}
                as="h5"
              >
                {" "}
                <Card.Title
                  style={{
                    fontSize: "15px",
                    fontWeight: "600",
                    color: "#587b86",
                  }}
                >
                  {title}
                </Card.Title>
                <FontAwesomeIcon
                  style={{
                    backgroundColor: "#587b86",
                    padding: "6px",
                    borderRadius: " 6px",
                    color: "white",
                  }}
                  icon={icon}
                  fixedWidth
                />
              </Card.Header>

              <Card.Body>
                <span style={{ fontSize: "14px" }}>{text}</span>
                <h5>{value}</h5>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>{" "}
    </div>
  );
};

export default HomeCard;
